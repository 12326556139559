var searchKeys = [{
  key: 'dates',
  label: '举报时间(GMT+8)',
  placeholder: ["开始时间", "结束时间"],
  dates: true,
  rules: [{
    type: 'array',
    required: false,
    message: '请选择时间段!'
  }]
}, {
  key: "reportuid",
  label: "举报人UID",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: "reportnickname",
  label: '举报人昵称',
  placeholder: '请输入',
  required: false,
  rules: [],
  input: true,
  trim: true
}, {
  key: "bereportedid",
  label: "被举报人UID",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: "bereportednickname",
  label: '被举报人昵称',
  placeholder: '请输入',
  required: false,
  rules: [],
  input: true,
  trim: true
}, {
  key: 'bereportedtype',
  label: '举报类型',
  placeholder: '举报类型',
  required: false,
  rules: [],
  select: true,
  initialValue: ''
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
var searchKeysChannel = [{
  key: 'dates',
  label: '举报时间(GMT+8)',
  placeholder: ["开始时间", "结束时间"],
  dates: true,
  rules: [{
    type: 'array',
    required: false,
    message: '请选择时间段!'
  }]
}, {
  key: "reportuid",
  label: "举报人UID",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: "reportnickname",
  label: '举报人昵称',
  placeholder: '请输入',
  required: false,
  rules: [],
  input: true,
  trim: true
}, {
  key: "bereportedid",
  label: "被举报群ID",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: "bereportednickname",
  label: '被举报人名称',
  placeholder: '请输入',
  required: false,
  rules: [],
  input: true,
  trim: true
}, {
  key: 'bereportedtype',
  label: '举报类型',
  placeholder: '举报类型',
  required: false,
  rules: [],
  select: true,
  initialValue: ''
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys, searchKeysChannel };