var columns = [{
  title: 'ID',
  dataIndex: 'id',
  key: 'id',
  width: '12%',
  scopedSlots: {
    customRender: 'id'
  },
  align: 'center'
}, {
  title: '举报人UID',
  dataIndex: 'reportuid',
  key: 'reportuid',
  width: '12%',
  scopedSlots: {
    customRender: 'reportuid'
  },
  align: 'center'
}, {
  title: '举报人昵称',
  dataIndex: 'reportnickname',
  key: 'reportnickname',
  width: '10%',
  scopedSlots: {
    customRender: 'reportnickname'
  },
  align: 'center'
}, {
  title: '被举报人UID',
  dataIndex: 'bereportedid',
  key: 'bereportedid',
  width: '12%',
  scopedSlots: {
    customRender: 'bereportedid'
  },
  align: 'center'
}, {
  title: '被举报人昵称',
  dataIndex: 'bereportednickname',
  key: 'bereportednickname',
  width: '10%',
  scopedSlots: {
    customRender: 'bereportednickname'
  },
  align: 'center'
}, {
  title: '举报类型',
  dataIndex: 'bereportedtype',
  key: 'bereportedtype',
  width: 100,
  scopedSlots: {
    customRender: 'bereportedtype'
  },
  align: 'center'
}, {
  title: '时间(GMT+8)',
  dataIndex: 'createtime',
  key: 'createtime',
  width: '15%',
  scopedSlots: {
    customRender: 'createtime'
  },
  align: 'center'
}, {
  title: '状态',
  dataIndex: 'reportedstatus',
  key: 'reportedstatus',
  width: '10%',
  scopedSlots: {
    customRender: 'reportedstatus'
  },
  align: 'center'
}, {
  title: '操作',
  key: 'actions',
  dataIndex: 'actions',
  fixed: "right",
  width: 150,
  scopedSlots: {
    customRender: 'actions'
  },
  align: 'center'
}];
var channleColums = [{
  title: 'ID',
  dataIndex: 'id',
  key: 'id',
  width: '12%',
  scopedSlots: {
    customRender: 'id'
  },
  align: 'center'
}, {
  title: '举报人UID',
  dataIndex: 'reportuid',
  key: 'reportuid',
  width: '12%',
  scopedSlots: {
    customRender: 'reportuid'
  },
  align: 'center'
}, {
  title: '举报人昵称',
  dataIndex: 'reportnickname',
  key: 'reportnickname',
  width: '10%',
  scopedSlots: {
    customRender: 'reportnickname'
  },
  align: 'center'
}, {
  title: '被举报群ID',
  dataIndex: 'bereportedid',
  key: 'bereportedid',
  width: '12%',
  scopedSlots: {
    customRender: 'bereportedid'
  },
  align: 'center'
}, {
  title: '被举报群名称',
  dataIndex: 'bereportednickname',
  key: 'bereportednickname',
  width: '10%',
  scopedSlots: {
    customRender: 'bereportednickname'
  },
  align: 'center'
}, {
  title: '举报类型',
  dataIndex: 'bereportedtype',
  key: 'bereportedtype',
  width: 100,
  scopedSlots: {
    customRender: 'bereportedtype'
  },
  align: 'center'
}, {
  title: '时间(GMT+8)',
  dataIndex: 'createtime',
  key: 'createtime',
  width: '15%',
  scopedSlots: {
    customRender: 'createtime'
  },
  align: 'center'
}, {
  title: '状态',
  dataIndex: 'reportedstatus',
  key: 'reportedstatus',
  width: '10%',
  scopedSlots: {
    customRender: 'reportedstatus'
  },
  align: 'center'
}, {
  title: '操作',
  key: 'actions',
  dataIndex: 'actions',
  fixed: "right",
  width: 150,
  scopedSlots: {
    customRender: 'actions'
  },
  align: 'center'
}];
export { columns, channleColums };